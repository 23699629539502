import React, {Fragment} from 'react';

const Renderer = ({days, hours, minutes, seconds}) => {
    return (
        <Fragment>
            <span className="ht-count">
                <span className="count-inner">
                    <span className="time-count">{days}</span>
                    <p class="small">days</p>
                </span>
            </span>
            <span className="ht-count">
                <span className="count-inner">
                    <span className="time-count">{hours}</span>
                    <p class="small">Hours</p>
                </span>
            </span>
            <span className="ht-count">
                <span className="count-inner">
                    <span className="time-count">{minutes}</span>
                    <p class="small">Minutes</p>
                </span>
            </span>
            <span className="ht-count">
                <span className="count-inner">
                    <span className="time-count">{seconds}</span>
                    <p class="small">Seconds</p>
                </span>
            </span>
        </Fragment>
    )
};

export default Renderer;
